<template>
  <div class="LTextFilter">
    <div class="selectedTag" v-if="dynamicTags.length">
      <el-tag
        :key="index"
        v-for="(tag, index) in dynamicTags"
        closable
        size="mini"
        :disable-transitions="true"
        @close="handleClose(index, tag)"
      >
        {{tag.name}}
      </el-tag>
    </div>
    <div
      class="classifyList"
      v-for="(item, index) in filterList"
      :key="index"
    >
      <div class="title" :style="{ width, textAlign: align }">
        {{ item.name }}
      </div>
      <div class="tagBox">
        <div class="tags">
          <div
            v-for="(citem, cindex) in item.data"
            :key="`ch${cindex}`"
            class="link"
            :class="{ active: isActive(citem) }"
            @click="tapText(citem, index)"
          >{{ citem.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LTextFilter',
  props: {
    filterList: {
      type: Array,
      default: () => [],
    },
    width: {
      type: [String],
      default: 'auto',
    },
    align: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      dynamicTags: [],
    };
  },
  methods: {
    tapText(item, i) {
      const theLineRepeat = this.filterList[i].data.some(val => this.dynamicTags.some(cval => val.name === cval.name));
      const index = this.dynamicTags.findIndex(val => this.filterList[i].data.some(cval => val.name === cval.name));
      if (!theLineRepeat) {
        this.dynamicTags.push(item);
      } else {
        this.dynamicTags.splice(index, 1, item);
      }
      this.$emit('refreshTable', { tags: this.dynamicTags, self: item, type: 1 });
    },
    handleClose(i, item) {
      this.dynamicTags.splice(i, 1);
      this.$emit('refreshTable', { tags: this.dynamicTags, self: item, type: 0 });
    },
    isActive(item) {
      return this.dynamicTags.some(val => item.name === val.name);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/element/element-variables.scss';
  .LTextFilter {
    margin-bottom: 20px;
    .selectedTag {
      margin-bottom: 10px;
      .el-tag {
        margin-right: 5px;
      }
    }
    .classifyList {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #e9e9e9;
      line-height: 30px;
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
      .title {
        background: #ddd;
        padding: 0 5px;
        font-size: 14px;
        font-weight: bold;
        color: #606266;
      }
      .tagBox {
        flex: 1;
        width: 100%;
        background: #f4f4f4;
        .tags {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .link {
            color: $--color-info;
            font-size: 14px;
            margin: 0 5px;
            cursor: pointer;
            &:hover {
              color: $--color-primary;
            }
            &.active {
              color: $--color-primary;
            }
          }
        }
      }
    }
  }
</style>
